import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

// This file is used at : [ building-your-mvp-a-step-by-step-guide.js | choosing-the-right-tech-slack-for-your-mvp.js | the-ux-best-practices-that-will-make-you-a-great-designer.js
//                          hiring-for-the-emotional-intelligence.js | how-to-kick-off-a-project.js | outsourcing-to-Albania-The-best-choice-for-businesses.js |
//                          the-5-core-skills-the-future-workforce-needs-to-have.js | the-battle-of-the-clouds-aws-vs.-azure.js |
//                        ]

const ArticleComponent = (props) => {
  const {
    articleHeadText,
    articleTextImage,
    articleEndImage,
    articleText,
    articleEndText,
    articleSecondImage,
    articleDescription,
  } = props;
  return (
    <div className="article-section">
      <div className="full-container">
        <p
          className="article-section-text"
          dangerouslySetInnerHTML={{ __html: articleHeadText }}
        ></p>
        <GatsbyImage
          alt="articleImage"
          image={articleTextImage}
          className="article-section-image"
        />
        <p
          className="article-section-text"
          dangerouslySetInnerHTML={{ __html: articleText }}
        ></p>
        <GatsbyImage
          alt="endArticleImage"
          image={articleSecondImage}
          className="article-section-image"
        />
        <p
          className="article-section-text"
          dangerouslySetInnerHTML={{ __html: articleEndText }}
        ></p>
        <GatsbyImage
          alt="endArticleImage"
          image={articleEndImage}
          className="article-section-image"
        />
        <p
          className="article-section-text"
          dangerouslySetInnerHTML={{ __html: articleDescription }}
        ></p>
      </div>
    </div>
  );
};
export default ArticleComponent;
