import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import ProfilePicture from "../../images/blank-profile-picture.webp";

// This file is used at : [ building-your-mvp-a-step-by-step-guide.js | choosing-the-right-tech-slack-for-your-mvp.js | the-ux-best-practices-that-will-make-you-a-great-designer.js
//                          hiring-for-the-emotional-intelligence.js | how-to-kick-off-a-project.js | outsourcing-to-Albania-The-best-choice-for-businesses.js |
//                          the-5-core-skills-the-future-workforce-needs-to-have.js | the-battle-of-the-clouds-aws-vs.-azure.js |
//                        ]

const ArticleHomeComponent = (props) => {
  const {
    articleTitle,
    articleText,
    articleData,
    articleAuthor,
    ArticleImage,
    ArticleProfileImage,
  } = props;

  return (
    <div
      className="article-home-section"
      style={{
        backgroundImage: `url('${ArticleImage?.images?.fallback.src}')`,
      }}
    >
      <div className="article-home-section-bg">
        <div className="full-container">
          <div className="article-home-section-wrapper">
            <div className="article-home-section-wrapper-text">
              <h1 className="article-home-section-title">{articleTitle}</h1>
              {/* <p
                className="article-home-section-text"
                dangerouslySetInnerHTML={{ __html: articleText }}
              ></p> */}
              <div className="article-home-section-data">
                <p className="article-home-section-data-text">
                  {articleAuthor}
                </p>
                {ArticleProfileImage ? (
                  <GatsbyImage
                    image={ArticleProfileImage}
                    className="article-home-section-data-avatar"
                    alt="profile picture"
                  />
                ) : (
                  <img
                    className="article-home-section-data-avatar"
                    src={ProfilePicture}
                    alt="profile picture"
                  />
                )}{" "}
                <p className="article-home-section-data-text">{articleData}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticleHomeComponent;
