import React from 'react';
import FooterComponent from '../component/footerComponent';
import Layout from '../component/layout';
import ArticleComponent from '../component/SuccessStories/articleComponent';
import ArticleHomeComponent from '../component/SuccessStories/articleHomeComponent';

const BlogPage = (props) => {
  const {
    pageContext: { title, data, seo },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <ArticleHomeComponent
        articleTitle={data.blogTitle}
        articleText={data.blogDescription}
        articleData={data.blogDate}
        articleAuthor={data.blogAuthor}
        ArticleImage={
          data.blogImage?.localFile?.childImageSharp?.gatsbyImageData
        }
        ArticleProfileImage={
          data.blogProfileImage?.localFile?.childImageSharp?.gatsbyImageData
        }
      />
      <ArticleComponent
        articleHeadText={data.blogParagraph1}
        articleTextImage={
          data.blogParagraphImage1?.localFile?.childImageSharp?.gatsbyImageData
        }
        articleText={data.blogParagraph2}
        articleSecondImage={
          data.blogParagraphImage2?.localFile?.childImageSharp?.gatsbyImageData
        }
        articleEndText={data.blogParagraph3}
        articleEndImage={
          data.blogParagraphImage3?.localFile?.childImageSharp?.gatsbyImageData
        }
        articleDescription={data.blogParagraph4}
      />
      <FooterComponent />
    </Layout>
  );
};
export default BlogPage;
